<template>
  <span v-if="customer">
  <!--  <div style="background-color: red;width: auto">-->
  <!--  <router-link style="background-color: pink" v-if="customer" :to="{ path: `/customers/${customer.id}` }" target="_blank">-->
  <!--    <span v-if="customer.type === constants.userTypes.legal_entity && customer.company_name">{{ customer.company_name }} </span>-->
  <!--    <span v-else>{{ customer.first_name }} {{ customer.last_name }} </span>-->
  <!--      &lt;!&ndash;    <span style="position: relative; top: -1px; left: 2px; transform: scale(0.45)" :class="'flag&#45;&#45;datatable flag flag-' + customer.flag_code"></span>&ndash;&gt;-->
  <!--&lt;!&ndash;    <span>&ndash;&gt;-->
  <!--      <span :class="'customers-table-flag flag flag-' + customer.flag_code"></span>-->
  <!--&lt;!&ndash;    </span>&ndash;&gt;-->
  <!--  </router-link>-->
  <!--  </div>-->
    <div class="p-d-flex p-ai-center p-flex-wrap">
      <CustomerStatusHistoryButton :customerStatus="customer.customerStatus" :customerId="customer.id" @change-customer-status="changeCustomerStatus"/>
      <router-link :to="{ path: `/customers/${customer.id}` }" target="_blank" class="p-mr-1">
        <span v-if="customer.type === constants.userTypes.legal_entity && customer.company_name">{{ customer.company_name }} </span>
        <span v-else>{{ customer.first_name }} {{ customer.last_name }} </span>
         <!--    <span style="position: relative; top: -1px; left: 2px; transform: scale(0.45)" :class="'flag&#45;&#45;datatable flag flag-' + customer.flag_code"></span>-->
         <!--    <span>-->
  <!--      <span :class="'customer-data-flag flag flag-' + customer.flag_code"></span>-->
         <!--    </span>-->
      </router-link>
      <div class="customer-data-flag-wrapper">
        <span :class="'flag flag-' + customer.flag_code"></span>
      </div>
    </div>
    <div class="table-small-text p-d-flex p-flex-wrap">
      <span v-show="showPhoneNumber">
        <span class="p-mr-2" v-if="customer.type === constants.userTypes.legal_entity"><span v-if="customer.company_phone_code">+{{ customer.company_phone_code}}</span> {{ customer.company_phone_number  }}</span>
        <span class="p-mr-2" v-else><span v-if="customer.phone_code">+{{ customer.phone_code }}</span> {{ customer.phone_number }}</span>
      </span>
      <span class="p-mr-2">
        <span class="ti-hummer"></span>
        <span class="p-text-bold p-ml-1">{{ customer.orders_count ?? 0 }}</span>
      </span>
      <span class="p-mr-2">
        <span class="ti-clipboard"></span>
        <span class="p-text-bold p-ml-1">{{ customer.offers_count ?? 0 }}</span>
      </span>
      <span>
        <span class="ti-car"></span>
        <span class="p-text-bold p-ml-1">{{ customer.cars_count ?? 0 }}</span>
      </span>
    </div>

  <!--  <ChangeCustomerStatusModal :visible="changeCustomerStatusModal"-->
  <!--                             :customerId="customer.id"-->
  <!--                             :newStatus="newCustomerStatus"-->
  <!--                             @change-customer-status="changeCustomerStatus"-->
  <!--                             @close="closeChangeCustomerStatusModal"/>-->
  </span>
  <i v-else class="ti-minus"></i>
</template>

<script>
import constants from "@/constants";
import CustomerStatusHistoryButton from "@/pages/users/components/CustomerStatusHistoryButton";
// import ChangeCustomerStatusModal from "@/pages/users/components/ChangeCustomerStatusModal";

export default {
  name: 'TableCustomerData',
  emits: ['change-customer-status'],
  components: { CustomerStatusHistoryButton },
  props: {
    customer: Object,
    showPhoneNumber: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // changeCustomerStatusModal: false,
      // newCustomerStatus: null,
      // customerStatus: {
      //   status_id: 1,
      //   comment: null
      // },
      constants
    }
  },
  // watch: {
  //   '$store.state.firstLayerIsOpened'() {
  //     if (!this.$store.state.firstLayerIsOpened) {
  //       if (this.changeCustomerStatusModal) {
  //         this.closeChangeCustomerStatusModal()
  //       }
  //     }
  //   },
  // },
  methods: {
  //   closeChangeCustomerStatusModal() {
  //     this.changeCustomerStatusModal = false
  //     this.$store.commit('toggleFirstLayer', false)
  //   },
  //   changeCustomerStatusOnClick(status) {
  //     if (status === null) {
  //       return false
  //     }
  //     this.newCustomerStatus = status
  //     this.changeCustomerStatusModal = true
  //     this.$store.commit('toggleFirstLayer', true)
  //     // this.customerStatus.status_id = status
  //     // console.log(status)
  //     // this.$emit('change-customer-status', this.customer.id, status)
  //   },
    changeCustomerStatus(newStatus) {
      // console.log(this.customer.id, )
      // this.customerStatus.status_id = status
      // console.log(status)
      this.$emit('change-customer-status', newStatus, this.customer?.id)
      // this.closeChangeCustomerStatusModal()
    }
  }
}
</script>

<!--<style lang="scss" scoped>-->
<!--.customer-phone-number:hover {-->
<!--  transform: scale(110%, 110%);-->
<!--}-->
<!--</style>-->